import React, {createContext} from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Auth0Provider} from "@auth0/auth0-react";
import history from "./utils/history";
import {getConfig} from "./config";
import auth0 from 'auth0-js';

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();


const params = new URLSearchParams(window.location.search);
const orgId = params.get('organization');
const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        'ext-redirect_uri': window.location.origin,
        ...(config.audience ? {audience: config.audience} : null),
    },
};

if (orgId) {
    providerConfig.authorizationParams['ext-org'] = orgId;
}

const webAuth = new auth0.WebAuth({
    domain: config.domain,
    clientID: config.clientId,
    responseType: 'code',
    redirectUri: window.location.origin,
    ...(config.audience ? {audience: config.audience} : null),
});

export const WebAuthContext = createContext(webAuth);

const root = createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        {...providerConfig}
    >
        <WebAuthContext.Provider value={webAuth}>
            <App/>
        </WebAuthContext.Provider>
    </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
