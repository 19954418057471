import React, { useState } from "react";
import { Button, Alert, Card, CardGroup } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import Highlight from "../components/Highlight";
import jwt from "jwt-decode";

export const LocationSampleApiComponent = () => {
  const { locationSampleApiOrigin, audience } = getConfig();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    resultType: "success",
    error: null,
    accessTokenValue: ""
  });
  const [endpoint, setEndpoint] = useState("");
  var isLoading = false;

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  
  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      isLoading = true;
      const token = await getAccessTokenSilently();
      const tokenJson = jwt(token);
      console.log("This is the access token "+ token);
      let resultTypeValue = "";
      const responseData = await fetch(`${locationSampleApiOrigin}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async response => {
        if (response.status >= 400) {
          resultTypeValue = "danger"
          var message = "";
          if (response.body != null) {
            message = await response.text();
          }
          isLoading = false;
          return `${response.status} : ${response.statusText} : ${message} - ${response.url}`;
        } else {
          resultTypeValue = "success"
          isLoading = false;
          return response.json();
        }
      })
          .catch(error => {
            console.log('this is the error '+ error);
            isLoading = false;
            return error;
          });

    //  const responseData = await response.json();

      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apiMessage: responseData,
        resultType: resultTypeValue,
        accessTokenValue: tokenJson
      }));
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
        
        <h1>Location Sample API</h1>
        <p className="lead">
          Ping the Location Sample API by clicking the button below.
        </p>

        <p>
          This will call the Location Sample API on port 7001 that would have been started
          if you run <code>dotnet run</code> on project Auth0SampleAPI. An access token is sent as part
          of the request's `Authorization` header and the API will validate it
          using the API's audience value.</p>
        
        <br/>
        <h4> Test Endpoints:  </h4>
        <div>  <Card><form>
          <label htmlFor="endpoint-field">
            Endpoint to Call : &nbsp;
          </label>
          <input
              id="endpoint-field"
              placeholder="cities/capitals"
              value={endpoint}
              onChange={(e ) => setEndpoint(e.target.value)}
          />
        </form>
        </Card></div>

        <Button
            color="primary"
            className="mt-5"
            onClick={callApi}
            disabled={!audience}
        >
          Ping API
        </Button>
      </div>
      

      <CardGroup>
        <Card>
          <h5>Public - No authorization</h5>
          <p> <button type="button" className="link-button" onClick={() => setEndpoint("public/date")}>public/date</button> : No authorization needed</p>
          <br/>
          <h5>Auth0 Authorized</h5>
          <p> <button type="button" className="link-button" onClick={() => setEndpoint("locations")}>locations</button> : View Locations. Requires permission <b>read:locations</b> assigned in auth0.
            This permission is assigned to role <b>LocationViewer</b>. Assigning the role to a user will grant the permission</p>

          <p> <button type="button" className="link-button" onClick={() => setEndpoint("locations/update")}>locations/update</button> : Update locations. Requires permission <b>update:locations</b> assigned in auth0.
            This permission is assigned to role <b>LocationAdmin</b>. Assigning the role to a user will grant the permission. </p>
          
          <p> <button type="button" className="link-button" onClick={() => setEndpoint("locations/revenue")}>locations/revenue</button> : View locations revenue. Requires permission <b>read:revenue</b> assigned in auth0.
            This permission is assigned to role <b>RevenueViewer</b>. Assigning the role to a user will grant the permission.</p>

          <p> <button type="button" className="link-button" onClick={() => setEndpoint("locations/organizations/org_32qeEnrB6gQRfr23")}>locations/organizations/org_32qeEnrB6gQRfr23</button> : View headquarters for specified organization.
            Requires the user to be signed in as a member of <b>Org Montana Tech (Org ID: org_32qeEnrB6gQRfr23)</b></p>
          <br/>
        </Card>

        <Card>
          <h6 className="muted">Result</h6>
          <Alert color={state.resultType}>
            <code>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </code>
          </Alert>
          <h5>Access Token</h5>
          <Highlight>{JSON.stringify(state.accessTokenValue, null, 2)}</Highlight>
        </Card>

      </CardGroup>
      
    </>
  );
};

export default withAuthenticationRequired(LocationSampleApiComponent, {
  onRedirecting: () => <Loading />,
});
