import {useAuth0} from "@auth0/auth0-react";
import {Button, Container, Row, Col, Card} from "reactstrap";
import {useContext, useEffect, useState} from "react";
import {BlobServiceClient} from '@azure/storage-blob';
import {useLocation} from 'react-router-dom'
import {WebAuthContext} from "../index";

const Login = () => {
    const {
        loginWithRedirect,
    } = useAuth0();

    const webAuth = useContext(WebAuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const params = new URLSearchParams(window.location.search);
    const orgId = params.get('organization');
    const [loadingBlob, setLoadingBlob] = useState(!!orgId);
    const [styles, setStyles] = useState({});

    useEffect(() => {
        if (!orgId) return;

        const containerName = 'auth0-branding';
        const blobName = `${orgId}/auth.json`;
        const blobServiceClient = new BlobServiceClient('https://orgportalstoragesandbox.blob.core.windows.net');
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);

        blobClient.download().then(result => {
            result.blobBody.then(downloaded => {
                downloaded.text().then(value => {
                    const json = JSON.parse(value);
                    setStyles(json);
                    setLoadingBlob(false);
                })
            })
        }).finally(() => {
            setLoadingBlob(false);
        });
    }, [orgId])


    const auth0LoginWithRedirect = (isSignup = false) => {
        const url = window.location.href;
        const inviteMatches = url.match(/invitation=([^&]+)/);
        const orgMatches = url.match(/organization=([^&]+)/);
        if (isSignup) {
            loginWithRedirect({
                authorizationParams: {
                    screen_hint: 'signup',
                },
            })
        } else if (inviteMatches && orgMatches) {
            loginWithRedirect({
                authorizationParams: {
                    organization: orgMatches[1],
                    invitation: inviteMatches[1],
                }
            });
        } else if (orgMatches) {
            loginWithRedirect({
                authorizationParams: {
                    organization: orgMatches[1]
                }
            });
        } else {
            loginWithRedirect();
        }
    };

    const onSignup = () => {
        const payload = {
            connection: 'Username-Password-Authentication',
            email,
            password
        };

        if (orgId) {
            payload.user_metadata = {
                organization: orgId
            }
        }

        webAuth.signup(payload, (err) => {
            if (err) {
                console.log(err);
            } else {
                webAuth.login({
                    realm: 'Username-Password-Authentication',
                    email,
                    password,
                    onRedirecting: function () {
                        auth0LoginWithRedirect()
                    },
                }, (err) => {
                    console.log(err);
                });
            }
        })
    }

    if (loadingBlob) {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <Container className="mb-4">
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <Row className="d-flex justify-content-center">
                <Col md={4}>
                    <Card className="text-center" style={{backgroundColor: styles.backgroundColor}}>
                        <img src="https://blog.submittable.com/wp-content/uploads/2015/09/Blog-WHITE-shield.png"/>
                        <p>Log in or sign up to continue</p>
                        <Button
                            id="qsLoginBtn"
                            color="primary"
                            className="mt-6"
                            onClick={() => auth0LoginWithRedirect(false)}>
                            Log in
                        </Button>
                        <br/>
                        {/*
                        <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" style={{width: '100%'}}/>
                        <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" style={{width: '100%'}}/>
                        <br/> */}
                        <Button
                            color="primary"
                            className="mt-6"
                            onClick={() => auth0LoginWithRedirect(true)}>
                            Sign Up
                        </Button>
                        {/*                        <br />
                        {orgId && <img src={`https://orgportalstoragesandbox.blob.core.windows.net/auth0-branding/${orgId}/logo.png`}/>}
                        <br/>
                        */}
                    </Card>

                </Col>
            </Row>
        </Container>
    );
};

export default Login;