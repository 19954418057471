import React from "react";
import { Alert } from "reactstrap";

const Error = ({error}) => {
    if (error == null){
       return null; 
    }
   else return (
<div className="next-steps my-5">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <Alert color="primary">
    <h3 className="my-5 text-center">{error.message}</h3>
    </Alert>
</div>)
};

export default Error;
