import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const Signin = () => {
    const {
        loginWithRedirect,
    } = useAuth0();

    useEffect(() => {
        const url = window.location.href;
        const inviteMatches = url.match(/invitation=([^&]+)/) || [];
        const orgMatches = url.match(/organization=([^&]+)/) || [];
        loginWithRedirect({
            authorizationParams: {
                organization: orgMatches[1],
                invitation: inviteMatches[1],
            }
        });
    }, [])
    
};

export default Signin;
