import React, { useState } from "react";
import { Button, Alert, Card, CardGroup } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import jwt from "jwt-decode";

export const FGAExampleComponent = () => {
  const { locationSampleApiOrigin, audience } = getConfig();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    resultType: "success",
    error: null,
    accessTokenValue: ""
  });
  const [endpoint, setEndpoint] = useState("");
  var isLoading = false;

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  
  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      isLoading = true;
      const token = await getAccessTokenSilently();
      const tokenJson = JSON.stringify(jwt(token));
      console.log("This is the access token "+ token);
      let resultTypeValue = "";
      const responseData = await fetch(`${locationSampleApiOrigin}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async response => {
        if (response.status >= 400) {
          resultTypeValue = "danger"
          var message = "";
          if (response.body != null) {
            message = await response.text();
          }
          isLoading = false;
          return `${response.status} : ${response.statusText} : ${message} - ${response.url}`;
        } else {
          resultTypeValue = "success"
          isLoading = false;
          return response.json();
        }
      })
          .catch(error => {
            console.log('this is the error '+ error);
            isLoading = false;
            return error;
          });
      
      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apiMessage: responseData,
        resultType: resultTypeValue,
        accessTokenValue: tokenJson
      }));
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
        
        <h1>Location Sample API</h1>
        <p className="lead">
          Ping the Location Sample API by clicking the button below.
        </p>

        <p>
          This will call the Location Sample API on port 7001 that would have been started
          if you run <code>dotnet run</code> on project Auth0SampleAPI. An access token is sent as part
          of the request's `Authorization` header and the API will validate it
          using the API's audience value.</p>

        <br/>
       
<br/>

      <CardGroup>
          <Card>
            <h5>FGA Authorized</h5>
            <b>Model rules:</b>
            <li>A document owner can_read and can_write their document</li>
            <li>A document viewer can_read a document</li>
            <li>I am a viewer of doc 12345</li>
            <br/>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/view/12345")}>documents/view/12345</button> : Checks if the user has can_read permission over object doc</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/create/456789")}>documents/create/456789</button> : Creates a document. Creates an owner relationship of user over object doc</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("documents/update/456789")}>documents/update/456789</button> : Updates a document. Checks is the user has relation can_write over doc</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("permissions/list/doc/can_read")}>permissions/list/doc/can_read</button> : View which docs I can read</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("permissions/list/doc/can_write")}>permissions/list/doc/can_write</button> : View which docs I can write to</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("permissions/roles/docViewer/viewer/no-access-doc")}>permissions/roles/docViewer/viewer/no-access-doc</button> : Creates a custom role with the specified permission</p>
            <p> <button type="button" className="link-button" onClick={() => setEndpoint("permissions/roles/assign/docViewer")}>permissions/roles/assign/docViewer</button> : Assigns the custom role to the signed in user</p>

          </Card>
        
        <Card>
          <h5>Access Token</h5>
          <code>
            <span>{JSON.stringify(state.accessTokenValue, null, 2)}</span>
          </code>
        </Card>
        </CardGroup>
        <br/>
        <h4> Test Endpoints:  </h4>
        <div>  <Card><form>
          <label htmlFor="endpoint-field">
            Endpoint to Call : &nbsp;
          </label>
          <input
              id="endpoint-field"
              placeholder="cities/capitals"
              value={endpoint}
              onChange={(e ) => setEndpoint(e.target.value)}
          />
        </form>
        </Card></div>

        <Button
            color="primary"
            className="mt-5"
            onClick={callApi}
            disabled={!audience}
        >
          Ping API
        </Button>
      </div>
        
      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Alert color={state.resultType}>
              <code>
                <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
              </code>
            </Alert>
          </div>
        )}
      </div>
      
    </>
  );
}

export default withAuthenticationRequired(FGAExampleComponent, {
  onRedirecting: () => <Loading />,
});
